import { ControllerFlowAPI, ReportError } from '@wix/yoshi-flow-editor';
import { CartModalStatus, TFunction } from '../../types/types';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { FormApi } from '../../api/FormApi';
import { Submission } from '@wix/forms-ui/types';
import { getFieldsValuesInStorage } from '../storageFunctions';
import { CartConflictErrorType, EmptyStateErrorType } from '../../types/errors';
import { getErrorByType } from '../errors/errors';
import { FormStatus } from '../../types/form-state';
import { ExperimentsConsts } from '../../consts/experiments';
import { getPageAPIData } from '../../api/PageAPIAdapter/pageApiAdapter';
import { isDayfulFlow } from '../validations/dayful';
import { createFormView } from '../mappers/service-form.mapper';
import {
  getEmailValue,
  getNumberOfParticipantsValue,
  isCartEnabled,
} from './utils/utils';
import { fetchInitialData } from './dataFetchers/fetchInitialData';
import { isServiceExist } from '../validations/Validations';
import { FormState, SlotService } from './types';
import { renderNoIndexMetaTag } from '../seo/seo';
import { mapNestedSlotToServices } from './mapNestedSlotToServices/mapNestedSlotToServices';
import { getDummyData } from './dataFetchers/dummyDataFetcher';
import {
  BookingsQueryParams,
  IFlowApiAdapter,
} from '../flow-api-adapter/types';
import { findOverlappingResource } from '../cart-conflict/cartConflict';
import type { FormNestedSlot } from '../../types/formSelectedSlots';
import { Form } from '../../types/ambassador/bookings/ambassador-services-catalog';
import { ServiceType } from '@wix/bookings-uou-types';
import { getCollapseFormValues } from '../mappers/collapseForm.mapper';

export async function createInitialState({
  currentState,
  t,
  flowApi,
  wixSdkAdapter,
  bookingsDataCapsule,
  formApi,
  reportError,
}: {
  currentState: Partial<FormState>;
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: IFlowApiAdapter;
  bookingsDataCapsule: BookingsDataCapsule;
  formApi: FormApi;
  reportError: ReportError;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;

  if (wixSdkAdapter.isSSR()) {
    return {
      status: FormStatus.SSR,
    } as FormState;
  }

  try {
    const formPageData = await getPageAPIData({
      formApi,
      flowAPI: flowApi,
    });

    if (
      wixSdkAdapter.isEditorMode() ||
      (wixSdkAdapter.isPreviewMode() &&
        !isServiceExist(formPageData?.formSelectedSlot))
    ) {
      return await getDummyData(
        formApi,
        wixSdkAdapter,
        flowApi,
        flowApi.experiments,
      );
    }

    if (!formPageData) {
      throw EmptyStateErrorType.INVALID_PAGE_API_DATA;
    }

    const {
      areCouponsAvailable,
      catalogData,
      benefitsWithPlanInfo,
      publicPlans,
      numberOfSessions,
      formSelectedSlot,
      memberships,
      isPricingPlanInstalled,
      isMemberAreaInstalled,
      errors,
      bookingsLineItemOptions,
      serviceOptionsAndVariants,
      memberDetails,
      formToUse,
      isUpsellPluginInstalled,
      policyToUse,
    } = await fetchInitialData({
      formApi,
      controllerConfig,
      wixSdkAdapter,
      formSelectedSlot: formPageData.formSelectedSlot,
      timezone: formPageData.timezone,
      experiments: flowApi.experiments,
      reportError,
    });

    renderNoIndexMetaTag(wixSdkAdapter);

    const emptyStateError = getErrorByType({
      errorType: EmptyStateErrorType,
      errors,
    });

    if (emptyStateError) {
      throw emptyStateError.errorType;
    }

    const preFilledValues: Maybe<Submission> = await getFieldsValuesInStorage(
      bookingsDataCapsule,
      BookingsQueryParams.FILLED_FIELDS,
    );

    const isAlwaysShowComplexPhoneFieldEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.AlwaysShowComplexPhoneField,
    );

    const isFixAvailableSlotsFromCartCalculationEnabled =
      flowApi.experiments.enabled(
        ExperimentsConsts.FixAvailableSlotsFromCartCalculation,
      );

    const isCart = await isCartEnabled({
      experiments: flowApi.experiments,
      wixSdkAdapter,
      businessInfo: catalogData!.businessInfo,
    });

    const {
      controllerConfig: { wixCodeApi },
      environment: { isPreview },
    } = flowApi;

    const isDayful = await isDayfulFlow(wixCodeApi, isPreview);

    const isServiceInCart = (bookingsLineItemOptions?.length! || 0) > 0;

    const summaryPaymentDetails = {
      totalPrice: 0,
      payLater: 0,
    };

    const {
      slotServices,
      firstSlotServices,
    }: {
      slotServices: { [key: string]: SlotService };
      firstSlotServices: SlotService;
    } = mapNestedSlotToServices({
      formSelectedSlot,
      catalogData,
      memberships,
      numberOfSessions: numberOfSessions!,
      serviceOptionsAndVariants,
      formPageData,
      bookingsLineItemOptions,
      isCart,
      formApi,
      wixSdkAdapter,
      settings,
      isPricingPlanInstalled,
      t,
      summaryPaymentDetails,
      publicPlans,
      benefitsWithPlanInfo,
      isFixAvailableSlotsFromCartCalculationEnabled,
    });

    const isBookOnBehalfEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.BookOnBehalf,
    );

    const collapseFormValues = getCollapseFormValues({
      isServiceInCart,
      bookingsLineItemOptions,
      memberDetails,
      editCollapseData: currentState.editCollapseData,
      isBookOnBehalfEnabled,
      form: formToUse as Form,
      businessInfo: catalogData!.businessInfo,
    });

    const formSchema = createFormView({
      slotServices,
      businessInfo: catalogData?.businessInfo!,
      t,
      serviceOptionsAndVariants,
      isAlwaysShowComplexPhoneFieldEnabled,
      preFilledValues,
      shouldShowContactFields: !collapseFormValues,
      form: formToUse as Form,
    });

    const email = getEmailValue(formSchema!);

    const numberOfParticipants = getNumberOfParticipantsValue(formSchema);

    const isShouldCheckConflictsAtFormLoadEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.ShouldCheckConflictsAtFormLoad,
    );

    if (isShouldCheckConflictsAtFormLoadEnabled && bookingsLineItemOptions) {
      if (
        firstSlotServices.service.availableSpots === 0 &&
        (firstSlotServices.service.type === ServiceType.COURSE ||
          firstSlotServices.service.type === ServiceType.GROUP)
      ) {
        errors.push({
          errorType: CartConflictErrorType.OVERLAPPING_SLOTS_ON_CART_ERROR,
        });
      } else {
        const overlappingResource = findOverlappingResource(
          formSelectedSlot?.nestedSlots! as FormNestedSlot[],
          bookingsLineItemOptions,
        );

        if (overlappingResource) {
          errors.push({
            errorType: CartConflictErrorType.OVERLAPPING_SLOTS_ON_CART_ERROR,
          });
        }
      }
    }

    return {
      serviceData: {
        slotServices,
        summaryPaymentDetails,
        isSingleService: Object.keys(slotServices).length === 1,
        maxNumberOfParticipants:
          firstSlotServices.service.maxNumberOfParticipants,
        maxNumberOfParticipantsWithoutPP:
          firstSlotServices.service.maxNumberOfParticipantsWithoutPP,
        formSchema,
        form: formToUse,
        cancellationPolicy: policyToUse?.cancellationPolicy,
      },
      formInputs: {
        email,
        numberOfParticipants,
      },
      activeFeatures: catalogData!.activeFeatures,
      benefitsWithPlanInfo,
      businessInfo: catalogData!.businessInfo,
      formSelectedSlot: formSelectedSlot!,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      isMemberAreaInstalled: isMemberAreaInstalled!,
      isUpsellPluginInstalled: isUpsellPluginInstalled!,
      collapseFormValues,
      ...(isCart
        ? {
          isCart,
          isServiceInCart,
        }
        : {}),
      errors,
      couponInfo: {
        areCouponsAvailable: areCouponsAvailable!,
        isCouponInputDisplayed: false,
      },
      editorContext: {
        isDummy: false,
      },
      status: currentState.status || FormStatus.INITIALIZING,
      overrideDefaultFieldsValues:
        currentState.overrideDefaultFieldsValues || false,
      dialog: undefined,
      cartModal: {
        status: CartModalStatus.CLOSED,
        lineItems: [],
      },
      isDayful,
      ...(bookingsLineItemOptions?.length ? { bookingsLineItemOptions } : {}),
      memberDetails,
    };
  } catch (formError) {
    return {
      errors: [formError],
    } as FormState;
  }
}
