import { Submission } from '@wix/forms-ui/dist/types/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { BookingsLineItemOption } from '@wix/bookings-checkout-api';
import { Form } from '../../types/ambassador/bookings/ambassador-services-catalog';
import { ContactDetails } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { BusinessInfo } from '../../types/types';

const getContactDetailsFormFieldIds = (form: Form) => ({
  nameFieldId: form.firstName?.fieldId!,
  lastNameFieldId: form.lastName?.fieldId!,
  emailFieldId: form.email?.fieldId!,
  phoneFieldId: form.phone?.fieldId!,
});

export const getContactDetailsWithFieldIds = ({
  form,
  contactDetails,
}: {
  form?: Form;
  contactDetails?: ContactDetails;
}): Submission => {
  const { nameFieldId, lastNameFieldId, emailFieldId, phoneFieldId } =
    getContactDetailsFormFieldIds(form!);

  const name =
    contactDetails?.firstName! +
    (!lastNameFieldId && contactDetails?.lastName
      ? ` ${contactDetails?.lastName}`
      : '');

  return {
    [nameFieldId]: name,
    ...(lastNameFieldId ? { [lastNameFieldId]: contactDetails?.lastName } : {}),
    [emailFieldId]: contactDetails?.email,
    [phoneFieldId]: contactDetails?.phone,
  };
};

export const getCollapseFormValues = ({
  isServiceInCart,
  editCollapseData,
  bookingsLineItemOptions,
  memberDetails,
  isBookOnBehalfEnabled = true,
  form,
  businessInfo,
}: {
  isServiceInCart?: boolean;
  editCollapseData?: boolean;
  bookingsLineItemOptions?: BookingsLineItemOption[];
  memberDetails?: Member;
  isBookOnBehalfEnabled?: boolean;
  form: Form;
  businessInfo: BusinessInfo;
}): ContactDetails | undefined => {
  let collapseFormValues: ContactDetails | undefined;

  if (editCollapseData) {
    return undefined;
  }

  if (isServiceInCart) {
    const contactDetailsFromFirstBookingOnCart =
      (bookingsLineItemOptions?.length || 0) >= 1
        ? bookingsLineItemOptions?.[0]?.contactDetails
        : undefined;

    collapseFormValues = contactDetailsFromFirstBookingOnCart;

    delete collapseFormValues?.fullAddress;
  } else if (memberDetails?.contact && isBookOnBehalfEnabled) {
    const { lastNameFieldId } = getContactDetailsFormFieldIds(form!);
    if (
      !memberDetails?.contact?.firstName ||
      (!memberDetails?.contact?.lastName && lastNameFieldId) ||
      businessInfo.isSMSReminderEnabled ||
      (form.phone?.userConstraints?.required &&
        (memberDetails.contact?.phones?.length === 0 ||
          !memberDetails.contact?.phones?.[0]?.trim()))
    ) {
      return undefined;
    }
    collapseFormValues = {
      email: memberDetails.contact?.emails?.[0] || memberDetails.loginEmail,
      firstName: memberDetails.contact.firstName,
      lastName: memberDetails.contact.lastName,
      phone: memberDetails.contact?.phones?.[0],
      contactId: memberDetails.contact.contactId,
    };
  }

  return collapseFormValues;
};
